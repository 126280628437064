// Set jQuery
window.$ = window.jQuery;

// import { Animations } from './animations/animations';
import { PageLoader } from './page-loader/loading';
import ScrollReveal from 'scrollreveal';
import * as tocbot from 'tocbot';

// Load PageLoader JS && document.ready
PageLoader().then(function () {

  console.log('ready!');

  // ScrollReveal().reveal('.content', { delay: 500, distance: '100px' });
  // ScrollReveal().reveal('.wp-block-mkl-section-block', { delay: 800, distance: '100px' });

  $('.mobile-menu-icon').click(function () {
    $('.menu-items').toggle();
  });

  $('.wrap-sl').each(function (index, element) {

    $(this).parent().addClass('wrapped');

    $(element).click(function(e) {
      if ($(this).text() == '+')
        $(this).text('-');
      else
        $(this).text('+');
      $(this).parent().children('.lwptoc_itemWrap').toggle();
    });

  });

  $('.menu-item').on('mouseenter mouseleave', function(e) {
    e.preventDefault();

    $(this).children('.child-menu').fadeToggle(30);
  });

  if ($('.page-template-template-guidetoc3').length > 0) pageGuideToc3();
  if ($('.page-template-template-guidetoc2').length > 0) pageGuideToc2();
  if ($('.page-template-template-guidetoc').length > 0) pageGuideToc();

  
});

function pageGuideToc3() {
  $('#content #post-content h1, #content #post-content h2, #content #post-content h3').each(
    function(index) {
      console.log(index);
      // console.log($(this)[0]);
      // $(this).prepend(index++ + '. ');
      let id_str = $(this).text().replace(/\s+/g, '-').toLowerCase();
      $(this).attr('id', id_str); 
    }
  );
  

tocbot.init({
  // Where to render the table of contents.
  tocSelector: '#content #toc',
  // Where to grab the headings to build the table of contents.
  contentSelector: '#content #post-content',
  // Which headings to grab inside of the contentSelector element.
  headingSelector: 'h1, h2, h3',
  // For headings inside relative or absolute positioned containers within content.
  hasInnerContainers: true,
  activeLinkClass: 'is-active-link',
  collapseDepth: 4,
  includeHtml: true,
    headingsOffset: 100,
  scrollSmoothOffset: -100
});
}

function pageGuideToc2() {
// prepare the array by adding level, ID and parent to each item of the array
function prepare( array ) {
    let idt, level, t;
    for( let i = 0, n = array.length; i < n; i++ ) {
        t       = array[ i ];
        t.el    = t;
        level   = parseInt( t.tagName[1], 10 );
        t.level = level;
        t.idt   = i + 1;

        if( level <= 1 ) t.parent = 0;
        if( i ) {
            if( array[ i - 1 ].level < level ) {
                t.parent = array[ i - 1 ].idt;
            } else if( array[ i - 1 ].level == level ) {
                t.parent = array[ i - 1 ].parent;
            } else {
                for( let j = i - 1; j >= 0; j-- ) {
                    if( array[ j ].level == level - 1) {
                        t.parent = array[ j ].idt;
                        break;
                    }
                }
            }
        }
    }
    return array;
}

// transform a flat array in a hierarchical array
function hierarchical( items ) {
    let hashTable = Object.create( null );
    items.forEach( (item) => {
    return hashTable[ item.idt ] = { ...item, subitems : [] } 
    }  
    );
    let tree = [];
    items.forEach( item => {
        if( item.parent )
            hashTable[ item.parent ].subitems.push( hashTable[ item.idt ] );
        else
            tree.push(hashTable[ item.idt ]);
    });
    return tree;
}

// function add_toc_index( titles ){
//   titles.forEach();
// }

// return an UL containing each title in a LI and possibly other items in UL sub-lists.
function add_list( titles ) {
    let li, a, anchor;
    let ol = document.createElement( "ol" );
    if( titles && titles.length ) {

        titles.forEach(function(t, index) {
          // console.log(t);
          // console.log(index);
            if( t.el.id ) anchor = t.el.id.toLowerCase();
            else anchor = t.el.textContent;
            if( ! anchor ) anchor = "inconnu";
            anchor = anchor.replace( /\W/g, "" );
            t.el.id = anchor.toLowerCase();
            li = document.createElement( "li" );
            a  = document.createElement( "a"  );
            a.href = `#${anchor.toLowerCase()}`;
            a.innerHTML = t.el.textContent;
            li.append( a );
            if( t.subitems && t.subitems.length ) {
                li.append( add_list( t.subitems ) );
            }
            ol.append( li );
        });
    }
    return ol;
}

//get the toc element
let divtoc = document.getElementById( "toc" );

// get the article element
let article  = document.getElementById( "post-content"  );
if( toc && article ) {
    let titles = article.querySelectorAll( "h1, h2, h3, h4, h5, h6" );
    titles = prepare( titles );
    titles = hierarchical( titles );
    // titles = add_toc_index( titles );
    let ol_racine = add_list( titles );
    toc.append( ol_racine );
}

$('#toc ol').each(function() {
  if ($(this).parent('li').length > 0) {
    $(this).parent('li').addClass('wrap');
    $(this).parent('li').children('a').before('<span class="wrap-toggle">+</span>');
  }
}
);

$('.wrap-toggle').click(function(){
  $(this).parent('li').toggleClass('wrap-open');

  if ($(this).text() == '+')
    $(this).text('-');
  else
    $(this).text('+');

})

$('#toc > ol > li').each(function(){
  $(this).toggleClass('wrap-open');

})

$('#toc ol li').each(function(){
 
  if ($(this).hasClass('wrap')) {
    $(this).find('span.wrap-toggle').html('+');
  }

})

$('#toc li a').click(function(){
  console.log($(this).attr("href"));
  let id = $(this).attr("href");
  jumpTo(id);
    function jumpTo(id){
      let top = $(id).offset().top;   
      console.log(top);
      $('#post-content').animate({
        scrollTop: top - 120
    }, 0);
    return false;
    }
});

// $('.wrap .wrap-toggle').text('+');
$('.wrap-open .wrap-toggle').text('-');

// $('#toc .wrap-open > ol').click(function(e){
//   e.preventDefault();
//   e.stopPropagation();
//   $(this).parent('.wrap').toggleClass('wrap-open');
  
//   if ($(this).parent('.wrap').children('.wrap-toggle').text() == '+')
//     $(this).parent('.wrap').children('.wrap-toggle').text('-');
//   else
//     $(this).parent('.wrap').children('.wrap-toggle').text('+');

// })

$('#toc a').click(function() {
  $(this).addClass('selected');
})


// force going to ID

let hash = window.location.hash;

if (hash) {
  jumpTo(hash);
}


  function jumpTo(id){
    let top = $(hash).offset().top;   
    console.log(top);
    $('#post-content').animate({
      scrollTop: top - 120
  }, 0);
  return false;
  }

  $('#content #post-content h1, #content #post-content h2, #content #post-content h3, #content #post-content h4, #content #post-content h5, #content #post-content h6').each(
    function(index) {
      $(this).append('<i class="fas fa-link"></i>');
      $(this).addClass('anchor'); 
    }
  );

      // Source: http://stackoverflow.com/questions/30734552/change-url-while-scrolling
    // stackoverflow.com/questions/123999/how-to-tell-if-a-dom-element-is-visible-in-the-current-viewport
    function isElementInViewport (el) {
      //special bonus for those using jQuery
      if (typeof jQuery === "function" && el instanceof jQuery) {
        el = el[0];
      }
      var rect = el.getBoundingClientRect();

      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom + 250 <= (window.innerHeight || document.documentElement.clientHeight) && /*or $(window).height() */
        rect.right <= (window.innerWidth || document.documentElement.clientWidth) /*or $(window).width() */
      );
    }
    // click-to-scroll behavior
    $(".anchor").click(function (e) {
      e.preventDefault();
      var section = this.href;
      var sectionClean = section.substring(section.indexOf("#"));
      $("html, body").animate({
        scrollTop: $(sectionClean).offset().top
      }, 1000, function () {
        window.location.hash = sectionClean;
      });
    });
    // listen for the scroll event
    $('#post-content').on("scroll", function() {
      console.log("onscroll event fired...");
      // check if the anchor elements are visible
      $("#post-content .anchor").each(function (idx, el) {
        if ( isElementInViewport(el) ) {
          // update the URL hash
          if (window.history.pushState) {
            var urlHash = "#" + $(el).attr("id");
            // window.history.pushState(null, null, urlHash);
          }
          const selec = $(el).attr("id");
          
          $('#toc').find('a').removeClass('selected');
          // $('#toc').find('ol').removeClass('selected-ol');
          $('#toc > ol > ol').find('a[href=#'+ selec +']').toggleClass('selected');
          $('#toc').find('a[href=#'+ selec +']').toggleClass('selected');
          // $('#toc').find('a[href=#'+ selec +']').parents('ol').addClass('selected-ol');
        }
      });
    });

}

function pageGuideToc() {
  $('#content #post-content h1, #content #post-content h2, #content #post-content h3').each(
      function(index) {
        // console.log($(this)[0]);
        // $(this).prepend(index++ + '. ');
        let id_str = $(this).text().replace(/\s+/g, '-').toLowerCase();
        $(this).attr('id', id_str); 
      }
    );
    

  tocbot.init({
    // Where to render the table of contents.
    tocSelector: '#content #toc',
    // Where to grab the headings to build the table of contents.
    contentSelector: '#content #post-content',
    // Which headings to grab inside of the contentSelector element.
    headingSelector: 'h1, h2, h3',
    // For headings inside relative or absolute positioned containers within content.
    hasInnerContainers: false,
    activeLinkClass: 'is-active-link',
    collapseDepth: 4,
    includeHtml: true,
      headingsOffset: 100,
    scrollSmoothOffset: -100
  });
}