export function PageLoader() {

    // setTimeout(function(){ $('.page-loader').fadeOut(400) }, 1000);

    let promise = new Promise((resolve, reject) => {
      $(window).on('load', function() {
        setTimeout(function() {

          $('.page-loader').fadeOut(400);

          resolve("page loaded.")
        }, 1000)
      });
    });

    return promise;

}

